function EmvCPM (bytes) {
  // parse
  this.__source = bytes
  this.__parse()
}

EmvCPM.prototype.__toString16 = function (val) {
  const result = val.toString(16).toUpperCase()
  return result.length === 1 ? ('0' + result) : result
}

EmvCPM.prototype.__parse = function () {
  this.__content = this.__readContent(this.__source)
  if (this.__content && this.__content['61'] && this.__content['61'].children && this.__content['61'].children['57']) {
    this.track2EquivalentData = this.__content['61'].children['57'].values.map(b => this.__toString16(b)).join('')
    this.primaryAccountNumber = this.track2EquivalentData.split('D')[0]
    this.isValid = true
  } else {
    this.isValid = false
  }
}

EmvCPM.prototype.__readContent = function (source) {
  const result = {
    payloads: []
  }
  let index = 0
  while (index < source.length) {
    const payload = this.__readNextPayload(source, index)
    if (payload) {
      result.payloads.push(payload)
      if (!result[payload.tag]) {
        result[payload.tag] = payload
      }

      index = payload.nextIndex
    } else {
      // not valid emc qr code
      return null
    }
  }
  return result
}

EmvCPM.prototype.__readNextPayload = function (source, index) {
  try {
    const tag = this.__toString16(source[index++])
    const length = source[index++]
    const values = source.slice(index, index + length)
    index = index + length

    const content = {
      tag,
      length,
      values,
      nextIndex: index
    }

    if (content.tag === '61') {
      // Application Template
      const children = this.__readContent(values)
      if (children) {
        content.children = children
        return content
      } else {
        // not valid emc qr code
        return null
      }
    } else {
      return content
    }
  } catch (e) {
    // out of bound
    return null
  }
}

export default EmvCPM
