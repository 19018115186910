import utils from '../utils'
import ElepayError from '../error'

function handle (type, object, credential, key, options, helper) {
  return new Promise((resolve, reject) => {
    if (!credential.redirectUrl) {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
    }
    let payUrl = utils.replaceKey(credential.redirectUrl, key)
    if (!!helper.config && !!helper.config.middlePage) {
      const params = {
        payUrl,
        charge: object.id,
        paymentMethod: object.paymentMethod,
        currency: object.currency,
        amount: object.amount,
        endPoint: options.endPoint || process.env.API_ENDPOINT
      }
      if (helper.config.extraParams) {
        Object.assign(params, helper.config.extraParams)
      }
      payUrl = utils.buildUrl(utils.replaceKey(helper.config.middlePage, key), params)
    }
    utils.redirect(payUrl)
    return setTimeout(() => resolve({}), 3000)
  })
}

export default {
  handleCharge (charge, credential, key, options, helper) {
    return handle.call(this, 'charge', charge, credential, key, options, helper)
  },
  handleSource (source, credential, key, options, helper) {
    return handle.call(this, 'source', source, credential, key, options, helper)
  },
  init (config) {
    return Promise.resolve(new DefaultHelper(config))
  },
  needInitBeforeHandle () {
    return true
  }
}

function DefaultHelper (config) {
  this.config = config
}
