import utils from '../utils'
import ElepayError from '../error'

export default {
  handleCharge (charge, credential) {
    return new Promise((resolve, reject) => {
      if (charge.resource !== 'web') {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid, resource is not supported'))
      }
      if (credential.payload) {
        utils.writeDocument(credential.payload)
        return setTimeout(() => resolve({}), 3000)
      } else if (credential.redirectUrl) {
        utils.redirect(credential.redirectUrl)
        return setTimeout(() => resolve({}), 3000)
      } else {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }
    })
  }
}
