import utils from '../utils'
import ElepayError from '../error'

export default {
  async handleCharge (charge, credential, key, options, helper, handleOptions) {
    return new Promise((resolve, reject) => {
      utils.redirect(utils.replaceKey(credential.redirectUrl, key))
      return setTimeout(() => resolve({}), 3000)
    })
  },
  init (config) {
    return utils.loadJs('payvault', config.sdkJs).then(() => {
      return new RakutencardHelper(config)
    }).catch((err) => {
      return Promise.reject(new ElepayError('1_000_000_50001', `failed to load provider sdk. ${err}`))
    })
  }
}

function RakutencardHelper (config) {
  this.config = config
  if (window.payvault) {
    this.payvault = window.payvault
  }
}
