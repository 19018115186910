import utils from '../utils'
import ElepayError from '../error'

function handle (type, object, credential) {
  return new Promise((resolve, reject) => {
    if (object.paymentMethod !== 'merpay') {
      return reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
    }
    if (object.resource !== 'web') {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid, resource is not supported`))
    }
    if (!credential.payload) {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
    }
    try {
      const payload = JSON.parse(credential.payload)
      if (payload.paymentUrl) {
        utils.redirect(payload.paymentUrl)
        return setTimeout(() => resolve({}), 3000)
      } else {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
    } catch (e) {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid. ${e}`))
    }
  })
}

export default {
  handleCharge (charge, credential, key, options, helper) {
    return handle.call(this, 'charge', charge, credential, key, options, helper)
  },
  handleSource (source, credential, key, options, helper) {
    return handle.call(this, 'source', source, credential, key, options, helper)
  }
}
