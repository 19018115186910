import utils from '../utils'
import ElepayError from '../error'

function handle (object, credential, key, options) {
  return new Promise((resolve, reject) => {
    if (object.resource === 'mini') {
      // eslint-disable-next-line no-undef
      wx.showModal({
        title: 'Payment Information',
        content: `¥ ${object.amount}`,
        success (res) {
          if (res.confirm) {
            // eslint-disable-next-line no-undef
            wx.request({
              url: `${options.endPoint}/charges/${object.id}/internaltest`,
              method: 'POST',
              data: {},
              header: {
                'content-type': 'application/json'
              },
              success (res) {
                resolve({
                  type: 'success'
                })
              },
              fail (res) {
                reject(new Error('failed to finish test charge'))
              }
            })
          } else if (res.cancel) {
            resolve({
              type: 'cancel'
            })
          }
        }
      })
    } else {
      if (!credential.redirectUrl) {
        return reject(new ElepayError('1_000_000_10105', `${object.object} object is not valid`))
      }
      if (object.extra && object.extra.frontUrl) {
        const params = utils.getRedirectDefaultParams(options)
        const redirectUrl = utils.replaceKey(credential.redirectUrl, key)
        utils.redirect(utils.buildUrl(redirectUrl, params))
        return setTimeout(() => resolve({}), 3000)
      } else {
        const modalElem = utils.createModal()
        const iframeElem = utils.createIFrame()
        const params = utils.getRedirectDefaultParams(options)
        const redirectUrl = utils.replaceKey(credential.redirectUrl, key)
        iframeElem.src = utils.buildUrl(redirectUrl, params)

        utils.appendIFrame(iframeElem, modalElem)
        document.body.appendChild(modalElem)

        const chargeOrigin = utils.parseOrigin(redirectUrl)
        const register = function (modalElem) {
          const unregister = utils.listenMessage(window, chargeOrigin, (cmd, data) => {
            // add message event handler
            if (cmd === 'close') {
              unregister()
              utils.destroyModal(modalElem).then(() => {
                resolve({
                  type: 'cancel'
                })
              })
            } else if (cmd === 'success') {
              unregister()
              utils.destroyModal(modalElem).then(() => {
                resolve({
                  type: 'success'
                })
              })
            } else if (cmd === 'failure') {
              unregister()
              utils.destroyModal(modalElem).then(() => {
                reject(data)
              })
            }
          })
        }
        register(modalElem)
      }
    }
  })
}

export default {
  handleCharge (charge, credential, key, options) {
    return handle(charge, credential, key, options)
  },
  handleSource (source, credential, key, options) {
    return handle(source, credential, key, options)
  }
}
