import utils from '../utils'
import ElepayError from '../error'

function handle (type, object, credential) {
  return new Promise((resolve, reject) => {
    if (object.paymentMethod !== 'linepay') {
      return reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
    }
    if (object.resource !== 'web' && object.resource !== 'liff') {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid, resource is not supported`))
    }
    if (credential.payload) {
      try {
        const payload = JSON.parse(credential.payload)
        if (payload.paymentUrl && payload.paymentUrl.web) {
          utils.redirect(payload.paymentUrl.web)
          return setTimeout(() => resolve({}), 3000)
        } else {
          return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
        }
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid. ${e}`))
      }
    } else if (credential.redirectUrl) {
      utils.redirect(credential.redirectUrl)
      return setTimeout(() => resolve({}), 3000)
    } else {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
    }
  })
}

export default {
  handleCharge (charge, credential, key, options, helper) {
    return handle.call(this, 'charge', charge, credential, key, options, helper)
  },
  handleSource (source, credential, key, options, helper) {
    return handle.call(this, 'source', source, credential, key, options, helper)
  },
  handleOpenUrl (state, options) {
    return new Promise((resolve, reject) => {
      const chargeId = state.chargeId
      const type = state.type
      const frontUrl = state.frontUrl
      const endPoint = options.endPoint || process.env.API_ENDPOINT

      if (type === 'confirm') {
        // capture
        return utils.axios.post(`${endPoint}/charges/${chargeId}/capture`, {
          extra: {}
        }, {
          auth: {
            username: this.key,
            password: ''
          }
        }).then(() => {
          utils.redirect(utils.buildUrl(frontUrl, { status: 'captured' }))
          return setTimeout(() => resolve({
            handled: true
          }), 3000)
        }).catch((err) => {
          console.error(err)

          utils.redirect(utils.buildUrl(frontUrl, { status: 'failure' }))
          return setTimeout(() => resolve({
            handled: true
          }), 3000)
        })
      } else {
        utils.redirect(utils.buildUrl(frontUrl, { status: 'failure' }))
        return setTimeout(() => resolve({
          handled: true
        }), 3000)
      }
    })
  }
}
