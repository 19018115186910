import utils from '../utils'
import ElepayError from '../error'

function handle (type, object, credential, key, options, helper) {
  return new Promise((resolve, reject) => {
    if (object.paymentMethod !== 'docomopay') {
      return reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
    }
    if (object.resource !== 'web') {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid, resource is not supported`))
    }

    if (credential.redirectUrl) {
      let redirectUrl = credential.redirectUrl
      if (credential.payload === 'waiting') {
        const params = utils.getRedirectDefaultParams(options)
        redirectUrl = utils.buildUrl(utils.replaceKey(redirectUrl, key), params)
      }
      utils.redirect(redirectUrl)
      return setTimeout(() => resolve({}), 3000)
    } else if (credential.payload) {
      utils.createAndAppendForm(credential.payload, true)
      return setTimeout(() => resolve({}), 3000)
    } else {
      return reject(new ElepayError('1_000_000_10105', `${object.object} object is not valid`))
    }
  })
}

export default {
  handleCharge (charge, credential, key, options, helper) {
    return handle('charge', charge, credential, key, options, helper)
  },
  handleSource (source, credential, key, options, helper) {
    return handle('source', source, credential, key, options, helper)
  },
  init (config) {
    return Promise.resolve(new DocomopayHelper(config))
  },
  needInitBeforeHandle () {
    return true
  }
}

function DocomopayHelper (config) {
  this.config = config
}
