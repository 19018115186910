import utils from '../utils'
import ElepayError from '../error'

export default {
  handleCharge (charge, credential, key, options) {
    return new Promise((resolve, reject) => {
      if (charge.paymentMethod !== 'paypal') {
        return reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
      }
      if (charge.resource !== 'web') {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid, resource is not supported'))
      }
      if (!credential.redirectUrl) {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }

      const modalElem = document.createElement('div')
      modalElem.setAttribute('style', 'display: block; position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; z-index: 19999; overflow-y: scroll;')
      modalElem.setAttribute('id', 'elepay-ui-modal')

      const iframeElem = document.createElement('iframe')
      iframeElem.setAttribute('id', 'elepay-ui-frame')
      iframeElem.setAttribute('style', 'width: 100%; height: 100%; border: none;')

      const params = utils.getRedirectDefaultParams(options)
      const redirectUrl = utils.replaceKey(credential.redirectUrl, key)
      iframeElem.src = utils.buildUrl(redirectUrl, params)

      utils.appendIFrame(iframeElem, modalElem)
      document.body.appendChild(modalElem)

      const chargeOrigin = utils.parseOrigin(redirectUrl)
      const register = function (modalElem) {
        const unregister = utils.listenMessage(window, chargeOrigin, (cmd, data) => {
          // add message event handler
          if (cmd === 'ready') {
            iframeElem.setAttribute('data-ready', 'true')
            utils.postMessage(iframeElem.contentWindow, chargeOrigin, 'mount')
          } else if (cmd === 'close') {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              resolve({
                type: 'cancel'
              })
            })
          } else if (cmd === 'success') {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              resolve({
                type: 'success'
              })
            })
          } else if (cmd === 'failure') {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              reject(data)
            })
          }
        })
      }
      register(modalElem)
    })
  },
  init (config) {
    return Promise.all([
      utils.loadJs('paypal', 'https://www.paypalobjects.com/api/checkout.js', {
        'data-version-4': '',
        'log-level': 'warn'
      }),
      utils.loadJs('braintree', 'https://js.braintreegateway.com/web/3.42.0/js/client.min.js'),
      utils.loadJs('braintree-paypal', 'https://js.braintreegateway.com/web/3.42.0/js/paypal-checkout.min.js')
    ]).then(() => {
      return new BraintreeHelper(config)
    }).catch((err) => {
      return Promise.reject(new ElepayError('1_000_000_50001', `failed to load provider sdk. ${err}`))
    })
  }
}

function BraintreeHelper (config) {
  this.braintree = window.braintree
  this.paypal = window.paypal
  this.config = config
}

BraintreeHelper.prototype.render = function (selector, clientToken, charge, options) {
  options = options || {}
  return this.braintree.client.create({
    authorization: clientToken
  }).then((clientInstance) => {
    return this.braintree.paypalCheckout.create({
      client: clientInstance
    })
  }).then((paypalCheckoutInstance) => {
    return this.paypal.Button.render({
      env: this.config.mode,
      style: {
        label: 'pay',
        size: 'responsive',
        shape: 'pill',
        color: 'gold',
        tagline: false
      },
      payment () {
        return paypalCheckoutInstance.createPayment({
          flow: 'checkout',
          intent: 'sale',
          amount: charge.amount,
          currency: charge.currency
        })
      },
      onAuthorize (data) {
        return paypalCheckoutInstance.tokenizePayment(data).then((payload) => {
          if (options && options.onFinish) {
            options.onFinish(payload)
          }
        }).catch((e) => {
          if (options && options.onError) {
            options.onError(e)
          }
        })
      },
      onCancel () {
        if (options && options.onCancel) {
          options.onCancel()
        }
      },
      onError (e) {
        if (options && options.onError) {
          options.onError(e)
        }
      }
    }, selector)
  })
}
