import utils from '../utils'
import ElepayError from '../error'

function handle (type, object, credential, key, options) {
  return new Promise((resolve, reject) => {
    if (object.resource !== 'web') {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid, resource is not supported`))
    }
    if (!credential.redirectUrl) {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
    }
    let redirectUrl = credential.redirectUrl
    if (type === 'charge' && credential.payload === 'waiting') {
      const params = utils.getRedirectDefaultParams(options)
      redirectUrl = utils.buildUrl(utils.replaceKey(redirectUrl, key), params)
    }
    utils.redirect(redirectUrl)
    return setTimeout(() => resolve({}), 3000)
  })
}

export default {
  handleCharge (charge, credential, key, options, helper) {
    return handle.call(this, 'charge', charge, credential, key, options, helper)
  },
  handleSource (source, credential, key, options, helper) {
    return handle.call(this, 'source', source, credential, key, options, helper)
  }
}
