import aesjs from 'aes-js'
import axios from 'axios'

import ElepayError from './error'
import EmvCpm from './emvcpm'

const instance = axios.create({
  timeout: 30000,
  headers: {
    'elepay-api-version': '2020-06-16',
    'elepay-sdk-version': `${process.env.VERSION}`,
    'elepay-sdk-source': 'js'
  }
})

instance.interceptors.response.use((response) => {
  return response
}, (err) => {
  if (err.response && err.response.status === 401) {
    // invalid key
    return Promise.reject(new ElepayError('1_000_000_10000', 'invalid api key'))
  } else if (err.response && err.response.data && err.response.data.code) {
    return Promise.reject(new ElepayError(err.response.data.code, err.response.data.message || ''))
  } else {
    return Promise.reject(new ElepayError('1_000_000_50000', 'unknown error'))
  }
})

const utils = {
  __axios: axios,
  axios: instance,
  toBytes (str) {
    if (!str || !str.length) {
      return []
    }
    const result = []
    for (let i = 0; i < str.length; i++) {
      result[i] = str.charCodeAt(i)
    }
    return result
  },
  base64encode: (function () {
    const tbl = { 64: 61, 63: 47, 62: 43 }
    for (let i = 0; i < 62; i++) {
      tbl[i] = i < 26 ? i + 65 : (i < 52 ? i + 71 : i - 4)
    }
    return function (arr) {
      if (!arr || !arr.length) {
        return ''
      }
      let result = ''
      for (let i = 0, len = arr.length; i < len; i += 3) {
        result += String.fromCharCode(
          tbl[arr[i] >>> 2],
          tbl[(arr[i] & 3) << 4 | arr[i + 1] >>> 4],
          tbl[i + 1 < len ? (arr[i + 1] & 15) << 2 | arr[i + 2] >>> 6 : 64],
          tbl[i + 2 < len ? (arr[i + 2] & 63) : 64]
        )
      }
      return result
    }
  })(),
  base64decode: (function () {
    const tbl = { 61: 64, 47: 63, 43: 62 }
    for (let i = 0; i < 62; i++) {
      tbl[i < 26 ? i + 65 : (i < 52 ? i + 71 : i - 4)] = i
    }
    return function (str) {
      if (!str || !str.length) {
        return []
      }
      const arr = []
      const buf = []
      for (let i = 0, len = str.length; i < len; i += 4) {
        for (let j = 0; j < 4; j++) {
          buf[j] = tbl[str.charCodeAt(i + j) || 0]
        }
        arr.push(
          buf[0] << 2 | (buf[1] & 63) >>> 4,
          (buf[1] & 15) << 4 | (buf[2] & 63) >>> 2,
          (buf[2] & 3) << 6 | buf[3] & 63
        )
      }
      if (buf[3] === 64) {
        arr.pop()
        if (buf[2] === 64) {
          arr.pop()
        }
      }
      return arr
    }
  })(),
  initCtr (pass) {
    const key = []
    for (let i = 0; i < 16; i++) {
      key[i] = i < pass.length ? pass.charCodeAt(i) : 0
    }
    const iv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    const CTR = aesjs.ModeOfOperation.ctr
    return new CTR(key, iv)
  },
  aes256decode (str, pass) {
    return aesjs.utils.utf8.fromBytes(utils.initCtr(pass).decrypt(utils.base64decode(str)))
  },
  aes256encode (str, pass) {
    return utils.base64encode(utils.initCtr(pass).encrypt(aesjs.utils.utf8.toBytes(str)))
  },
  uniqueId () {
    return '_' + Math.random().toString(36).substr(2, 9)
  },
  isArray (obj) {
    if (Array.isArray) {
      return Array.isArray(obj)
    } else {
      return Object.prototype.toString.call(obj) === '[object Array]'
    }
  },
  stringify (data, needEncode) {
    return Object.keys(data).map((key) => `${key}=${needEncode ? encodeURIComponent(data[key]) : data[key]}`).join('&')
  },
  find (list, key, value) {
    if (utils.isArray(list)) {
      return list.reduce((accumulator, current) => {
        if (accumulator) {
          return accumulator
        } else if (current && current[key] === value) {
          return current
        }
      })
    } else {
      return null
    }
  },
  buildUrl (url, params) {
    return url + (url.indexOf('?') >= 0 ? '&' : '?') + utils.stringify(params, true)
  },
  replaceKey (url, key) {
    return url.replace('<key>', key).replace('__key__', key)
  },
  redirect (url, replace) {
    if (typeof window === 'undefined') {
      console.log('not a browser, redirect url: ' + url)
      return
    }
    if (replace && window.location && window.location.replace) {
      window.location.replace(url)
    } else {
      window.location.href = url
    }
  },
  parseOrigin (url) {
    let link = document.createElement('a')
    link.href = url
    const origin = link.protocol + '//' + link.host
    link = null
    return origin
  },
  parseQuery (search) {
    const query = {}
    const qs = search || window.location.search
    if (qs.indexOf('?') === 0) {
      const array = qs.substr(1).split('&')
      array.forEach(element => {
        const parts = element.split('=')
        query[parts[0]] = decodeURIComponent(parts[1])
      })
    }
    return query
  },
  writeDocument (content) {
    document.open()
    document.write(content)
    document.close()
  },
  loadJs (id, url, options) {
    const headEle = document.getElementsByTagName('head')[0]
    return new Promise((resolve, reject) => {
      if (!document.getElementById(id)) {
        const scriptEle = document.createElement('script')
        scriptEle.setAttribute('type', 'text/javascript')
        scriptEle.setAttribute('src', url)
        scriptEle.setAttribute('id', id)
        scriptEle.async = true

        if (options) {
          Object.keys(options).forEach((key) => {
            scriptEle.setAttribute(key, options[key])
          })
        }

        scriptEle.addEventListener('load', resolve)
        scriptEle.addEventListener('error', reject)
        scriptEle.addEventListener('abort', reject)

        headEle.appendChild(scriptEle)
      } else {
        resolve()
      }
    })
  },
  addEventListener (elem, type, listener, useCapture) {
    elem.addEventListener(type, listener, useCapture)
    return function () {
      elem.removeEventListener(type, listener, useCapture)
    }
  },
  postMessage (w, origin, cmd, data) {
    w.postMessage(JSON.stringify({
      cmd, data
    }), origin)
  },
  listenMessage (w, origin, listener) {
    return utils.addEventListener(w, 'message', (ev) => {
      if (ev.origin === origin) {
        try {
          const evData = JSON.parse(ev.data)
          listener(evData.cmd, evData.data)
        } catch (e) {}
      }
    })
  },
  destroyModal (modalElem = null, remainModalElem) {
    return new Promise(resolve => {
      if (!modalElem) modalElem = document.getElementById('elepay-ui-modal')
      if (modalElem) {
        const iframes = modalElem.querySelectorAll('iframe')
        for (const iframe of Object.values(iframes)) {
          if (iframe.dataset.historyLength) {
            window.history.pushState(null, null, window.location.href)
            const diff = iframe.dataset.historyLength - window.history.length - 1
            const index = Math.min(diff, 0)
            index && window.history.go(index)
          }
        }
        if (remainModalElem) {
          for (const iframe of Object.values(iframes)) {
            if (iframe.id === 'elepay-ui-frame') {
              iframe.remove()
            }
          }
        } else {
          modalElem.remove()
        }
        if (window.focus) {
          window.focus()
        }
      }

      // window.history.go() may not be a synchronous operation
      // Here have to wait
      setTimeout(resolve, 100)
    })
  },
  createModal () {
    const modalElem = document.createElement('div')
    modalElem.setAttribute('style', 'display: block; position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; z-index: 19999;')
    modalElem.setAttribute('id', 'elepay-ui-modal')
    return modalElem
  },
  createIFrame () {
    const iframeElem = document.createElement('iframe')
    iframeElem.setAttribute('id', 'elepay-ui-frame')
    iframeElem.setAttribute('style', 'width: 100%; height: 100%; border: none;')
    return iframeElem
  },
  appendIFrame (iframeElem, parentElem, ignoreHistory = false) {
    parentElem.appendChild(iframeElem)
    if (!ignoreHistory) {
      window.history.pushState(null, null, window.location.href)
      iframeElem.dataset.historyLength = window.history.length
    }
  },
  createAndAppendForm (formHTML, autoSubmit) {
    const formContainer = document.createElement('div')
    formContainer.setAttribute('style', 'display: none;')
    formContainer.innerHTML = formHTML
    document.body.appendChild(formContainer)

    const formElem = formContainer.getElementsByTagName('form')[0]
    if (autoSubmit) {
      formElem.submit()
    }
    return formElem
  },
  callBridge (type, data) {
    if (window.elepayAndroid) {
      const methodName = `on${type}`
      if (window.elepayAndroid[methodName]) {
        window.elepayAndroid[methodName](data)
        return true
      }
    } else if (window.webkit && window.webkit.messageHandlers) {
      const methodName = `elepayOn${type}`
      if (window.webkit.messageHandlers[methodName] && window.webkit.messageHandlers[methodName].postMessage) {
        window.webkit.messageHandlers[methodName].postMessage(data)
        return true
      }
    }
  },
  getRedirectDefaultParams (options) {
    const {
      locale,
      theme,
      endPoint = process.env.API_ENDPOINT
    } = options

    const params = {
      origin: window.location.origin,
      endPoint: endPoint
    }

    if (locale) params.locale = locale

    if (theme) {
      try {
        params.theme = encodeURIComponent(JSON.stringify(theme))
      } catch (err) {}
    }

    return params
  },
  canMakeApplePayPayments () {
    return new Promise((resolve, reject) => {
      try {
        const result = !!(window.ApplePaySession && window.ApplePaySession.canMakePayments())
        resolve(result)
      } catch (err) {
        resolve(false)
      }
    })
  },
  emvCPMdecode (str) {
    return new EmvCpm(utils.base64decode(str))
  },
  detectCPM (str, rules) {
    let result = null
    if (utils.isArray(rules)) {
      // check as barcode
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i]
        if (rule.barcode && new RegExp(rule.barcode).test(str)) {
          result = rule.paymentMethod
          break
        }
      }
      if (!result) {
        // check as emvqr
        const emvCpm = utils.emvCPMdecode(str)
        if (emvCpm.isValid) {
          for (let i = 0; i < rules.length; i++) {
            const rule = rules[i]
            if (rule.emv && new RegExp(rule.emv).test(emvCpm.primaryAccountNumber)) {
              result = rule.paymentMethod
              break
            }
          }
        }
      }
    } else {
      const keys = Object.keys(rules)
      for (let i = 0; i < keys.length; i++) {
        if (new RegExp(rules[keys[i]]).test(str)) {
          result = keys[i]
          break
        }
      }
    }
    return result
  }
}

export default utils
