// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./', false, /\.js$/)

// you do not need `import paypay from './paypay'`
// it will auto require all vuex module from modules file
export default modulesFiles.keys().reduce((modules, modulePath) => {
  // set './paypay.js' => 'paypay'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  if (moduleName === 'index') return modules
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})
