import utils from '../utils'
import ElepayError from '../error'

function handle (type, object, credential, key, options, helper) {
  return new Promise((resolve, reject) => {
    if (object.paymentMethod !== 'amazonpay') {
      return reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
    }
    if (object.resource !== 'web') {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid, resource is not supported`))
    }

    if (helper.config.v2) {
      const initCheckout = function () {
        const data = JSON.parse(credential.payload)
        window.amazon.Pay.initCheckout({
          merchantId: helper.config.merchantId, // required
          ledgerCurrency: object.currency || 'JPY', // required
          sandbox: helper.config.sandbox,
          placement: 'Checkout', // required
          productType: 'PayOnly',
          createCheckoutSessionConfig: {
            payloadJSON: data.payload,
            signature: data.signature,
            publicKeyId: helper.config.publicKeyId
          }
        })
      }
      // v2
      if (credential.redirectUrl) {
        // need render button
        const params = utils.getRedirectDefaultParams(options)
        const redirectUrl = utils.replaceKey(credential.redirectUrl, key)
        const url = utils.buildUrl(redirectUrl, params)

        const modalElem = utils.createModal()
        const iframeElem = utils.createIFrame('amazonpay')
        iframeElem.src = url

        utils.appendIFrame(iframeElem, modalElem)
        document.body.appendChild(modalElem)

        const chargeOrigin = utils.parseOrigin(url)
        const unregister = utils.listenMessage(window, chargeOrigin, (cmd) => {
          // add message event handler
          if (cmd === 'cancelled') {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              resolve({
                type: 'cancel'
              })
            })
          } else if (cmd === 'checkout') {
            initCheckout()
          }
        })
      } else {
        initCheckout()
      }
    } else {
      const params = utils.getRedirectDefaultParams(options)
      const redirectUrl = utils.replaceKey(credential.redirectUrl, key)
      const url = utils.buildUrl(redirectUrl, params)

      // v1
      utils.redirect(url)
      return setTimeout(() => resolve({}), 3000)
    }
  })
}

function init (config, paymentMethod, options) {
  const jsSrc = config.sdkJs || (
    config.v2
      ? 'https://static-fe.payments-amazon.com/checkout.js'
      : 'https://static-fe.payments-amazon.com/OffAmazonPayments/jp/sandbox/lpa/js/Widgets.js'
  )
  return utils.loadJs('amazonpay', jsSrc)
    .then(() => {
      return new Helper(config, options)
    })
    .catch((err) => {
      return Promise.reject(new ElepayError('1_000_000_50001', `failed to load provider sdk. ${err}`))
    })
}

function Helper (config = {}, options = {}) {
  this.config = config
  this.options = options
}

export default {
  handleCharge (charge, credential, key, options, helper) {
    return handle.call(this, 'charge', charge, credential, key, options, helper)
  },
  handleSource (source, credential, key, options, helper) {
    return handle.call(this, 'source', source, credential, key, options, helper)
  },
  needInitBeforeHandle (charge) {
    return charge.paymentMethod === 'amazonpay'
  },
  init
}
