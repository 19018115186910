import utils from '../utils'
import ElepayError from '../error'

export default {
  handleCharge (charge, credential, key, options, helper) {
    return new Promise((resolve, reject) => {
      if (charge.paymentMethod !== 'atone') {
        return reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
      }
      if (charge.resource !== 'web') {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid, resource is not supported'))
      }
      if (!credential.redirectUrl) {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }

      const modalElem = utils.createModal()
      const iframeElem = utils.createIFrame()
      const params = utils.getRedirectDefaultParams(options)
      const redirectUrl = utils.replaceKey(credential.redirectUrl, key)
      iframeElem.src = utils.buildUrl(redirectUrl, params)

      utils.appendIFrame(iframeElem, modalElem)
      document.body.appendChild(modalElem)

      const chargeOrigin = utils.parseOrigin(redirectUrl)

      // Atone 是在 SDK WEB 中打開一個 iframe，並且會與 top window 進行通信
      // 但 SDK WEB 畫面也是在 iframe 中打開，導致該畫面的 window 已經不是 top window
      // 因此會造成 SDK WEB 畫面接收接受不到 message
      // 這段代碼的目的是將 message 轉發到 SDK WEB
      window.addEventListener('message', event => {
        if (event.data && event.data.msg) {
          iframeElem.contentWindow &&
          iframeElem.contentWindow.postMessage(event.data, chargeOrigin)
        }
      })

      // Receives the processing results from the child frame.
      // Made by the sdk-web
      const unregister = utils.listenMessage(window, chargeOrigin, (cmd, data) => {
        // add message event handler
        if (cmd === 'close') {
          unregister()
          utils.destroyModal(modalElem).then(() => {
            resolve({
              type: 'cancel'
            })
          })
        } else if (cmd === 'success') {
          unregister()
          utils.destroyModal(modalElem).then(() => {
            resolve({
              type: 'success'
            })
          })
        } else if (cmd === 'failure') {
          unregister()
          utils.destroyModal(modalElem).then(() => {
            reject(data)
          })
        }
      })
    })
  },
  init (config) {
    const jsSrc = config.sdkJs || 'https://ct-auth.a-to-ne.jp/v1/atone.js'
    return utils.loadJs('atone', jsSrc).then(() => {
      return new AtoneHelper(config)
    }).catch((err) => {
      return Promise.reject(new ElepayError('1_000_000_50001', `failed to load provider sdk. ${err}`))
    })
  }
}

function AtoneHelper (config) {
  this.config = config
}

AtoneHelper.prototype.launch = function (charge) {
  // 必要なデータ
  const data = charge.extra
  // let data = {
  //   'amount': charge.amount, // * 価格
  //   'shop_transaction_no': 'shop-tran-no-015', // * 加盟店取引ID
  //   'checksum': 'Eba8b4JtD+inOc/zRON0D4RfODMfXwsz1hCdAmrq1CI=', // * チェックサム
  //   'customer': { // * 購入者
  //     'customer_name': charge.extra.buyerName || 'abc' // * 購入者氏名
  //   },
  //   'items': [ // * 商品明細
  //     {
  //       'shop_item_id': 'item-012', // * 加盟店商品ID
  //       'item_name': charge.extra.productName || 'abc', // * 商品名
  //       'item_price': charge.amount, // * 商品単価
  //       'item_count': 1 // * 個数
  //     }
  //   ]
  // }

  return new Promise((resolve, reject) => {
    try {
      const Atone = window.Atone
      let token

      Atone.config({
        // pre_token: '',
        pub_key: this.config.sdkKey,
        payment: data,

        // 認証が完了したタイミング、または会員登録が完了したタイミングで呼び出し
        authenticated: function (authToken, userNo) {
          // authToken : 'tk_a2c4e6c8zPl2E+#5@=89s2q4'
          // userNo : '0000001'
          token = authToken
        },

        // モーダルを閉じたタイミングで呼び出し
        cancelled: function () {
          resolve({ status: 'cancel' })
        },

        // 決済がNGとなった後、ボタンを押してフォームを閉じたタイミングで呼び出し
        failed: function (response) {
          // response.id : 'tr_V5ifmUlcFZ5tC8uJ'
          // response.authorization_result : 2 # 2:NG
          // response.authorization_result_ng_reason : 9 # 1: 金額超過, 9:その他
          resolve({ status: 'error' })
        },

        // 決済がOKとなり自動でフォームが閉じたタイミングで呼び出し
        succeeded: function (response) {
          // response.id : 'tr_V5ifmUlcFZ5tC8uJ'
          // authorization_result : 1 # 1:OK
          resolve({
            status: 'finish',
            transactionId: response.id,
            token
          })
        },

        // 決済モジュールへ連携されたpaymentの値がサーバサイドで変更された場合、再度決済モジュールへ同期するためのファンクションです。必要に応じて実装をお願いします。※チェックサムの再生成が必要です。
        // エラーが発生したタイミングで呼び出し
        error: function (name, message, errors) {
          // name : 発生したエラー名が入ります。
          // message : javascriptで発生したエラーの内容がセットされます。
          // errors : 会員決済モジュール（サーバー側）より返却したjson objectを格納します。
          resolve({ status: 'error' })
        }
      }, Atone.start)
    } catch (err) {
      reject(new ElepayError('1_000_000_50000', `unknown error::${err}`))
    }
  })
}
