import utils from '../utils'
import ElepayError from '../error'

export default {
  handleCreditcard (type, object, credential, key, options, helper, handleOptions) {
    return new Promise((resolve, reject) => {
      if (!credential.payload) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
      let payload
      try {
        payload = JSON.parse(credential.payload)
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid. ${e}`))
      }
      if (!payload.creditcard) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
      const containerElem = handleOptions && handleOptions.containerDiv ? document.getElementById(handleOptions.containerDiv) : null
      const modalElem = containerElem || utils.createModal()
      const iframeElem = utils.createIFrame()
      const params = utils.getRedirectDefaultParams(options)
      if (payload.clientSecret) {
        params.clientSecret = payload.clientSecret
      }
      if (containerElem) {
        params.container = 'true'
      }
      const redirectUrl = utils.buildUrl(utils.replaceKey(payload.creditcard, key), params)
      iframeElem.src = redirectUrl

      utils.appendIFrame(iframeElem, modalElem)
      if (!containerElem) {
        document.body.appendChild(modalElem)
      }

      const origin = utils.parseOrigin(redirectUrl)
      const register = function (modalElem) {
        const unregister = utils.listenMessage(window, origin, (cmd, data) => {
          // add message event handler
          if (cmd === 'mounted') {
            iframeElem.setAttribute('data-ready', 'true')
            utils.postMessage(iframeElem.contentWindow, origin, 'ready')
          } else if (cmd === 'close') {
            unregister()
            utils.destroyModal(modalElem, !!containerElem).then(() => {
              resolve({
                type: 'cancel'
              })
            })
          } else if (cmd === 'token') {
            // TODO when to use
            unregister()
            resolve({
              type: 'token',
              token: data
            })
          } else if (cmd === 'success') {
            unregister()
            utils.destroyModal(modalElem, !!containerElem).then(() => {
              resolve({
                type: 'success'
              })
            })
          } else if (cmd === 'failure') {
            unregister()
            utils.destroyModal(modalElem, !!containerElem).then(() => {
              reject(data)
            })
          }
        })
      }
      register(modalElem)
    })
  },
  handleApplePayCharge (type, object, credential, key, options, helper) {
    return new Promise((resolve, reject) => {
      if (!credential.payload) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
      let payload
      try {
        payload = JSON.parse(credential.payload)
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid. ${e}`))
      }
      if (!payload.applepay) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
      const redirectUrl = utils.replaceKey(payload.applepay, key)
      this.processApplePayOrGooglePay(type, object, credential, key, options, helper, payload, redirectUrl, resolve, reject)
    })
  },
  handleGooglePay (type, object, credential, key, options, helper) {
    return new Promise((resolve, reject) => {
      if (!credential.payload) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
      let payload
      try {
        payload = JSON.parse(credential.payload)
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid. ${e}`))
      }
      if (payload.googlepay) {
        const redirectUrl = utils.replaceKey(payload.googlepay, key)
        this.processApplePayOrGooglePay(type, object, credential, key, options, helper, payload, redirectUrl, resolve, reject)
      } else {
        // legacy google pay
        // label
        const label = (object.extra && object.extra.shopName) || object.description || payload.appName || 'Summary'
        const endPoint = options.endPoint || process.env.API_ENDPOINT
        const paymentRequest = helper.stripe.paymentRequest({
          country: 'JP',
          currency: type === 'charge' ? object.currency.toLowerCase() : 'jpy',
          total: {
            label,
            amount: type === 'charge' ? object.amount : 0,
            pending: type !== 'charge'
          },
          jcbEnabled: !helper.config.jcbDisabled
        })

        const done = function (ev) {
          resolve({
            type: 'success'
          })
        }
        const failure = function (ev, err) {
          ev.complete('fail')
          reject(err)
        }
        const self = this
        if (payload.clientSecret) {
          paymentRequest.on('paymentmethod', (ev) => {
            if (type === 'source') {
              self.handleSetupIntentConfirmed(helper, endPoint, key, object, payload, ev).then(() => {
                done(ev)
              }).catch((err) => {
                failure(ev, err)
              })
            } else {
              self.handlePaymentIntentConfirmed(helper, endPoint, key, object, payload, ev).then(() => {
                done(ev)
              }).catch((err) => {
                failure(ev, err)
              })
            }
          })
        } else {
          paymentRequest.on('token', (ev) => {
            if (type === 'source') {
              self.handleSourceToken(endPoint, key, object, ev).then(() => {
                ev.complete('success')
                done(ev)
              }).catch((err) => {
                failure(ev, err)
              })
            } else {
              self.handleChargeToken(endPoint, key, object, ev).then(() => {
                ev.complete('success')
                done(ev)
              }).catch((err) => {
                failure(ev, err)
              })
            }
          })
        }
        paymentRequest.on('cancel', () => {
          resolve({
            type: 'cancel'
          })
        })
        paymentRequest.canMakePayment().then((result) => {
          if (result) {
            paymentRequest.show()
          } else {
            reject(new ElepayError('1_000_000_50000', 'google pay is not available in this browser'))
          }
        })
      }
    })
  },
  handlePaymentIntentConfirmed (helper, endPoint, key, object, payload, ev) {
    return helper.stripe.confirmCardPayment(payload.clientSecret, {
      payment_method: ev.paymentMethod.id
    }, {
      handleActions: false
    }).then((confirmResult) => {
      if (confirmResult.error) {
        return Promise.reject(new Error(confirmResult.error.message))
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success')
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (confirmResult.paymentIntent.status === 'requires_action' ||
            confirmResult.paymentIntent.status === 'requires_source_action') {
          return helper.stripe.confirmCardPayment(payload.clientSecret).then((result) => {
            if (result.error) {
              return Promise.reject(new Error(result.error.message))
            } else {
              return this.checkChargeStatusForPaymentIntent(endPoint, key, object.id)
            }
          })
        } else {
          return this.checkChargeStatusForPaymentIntent(endPoint, key, object.id)
        }
      }
    })
  },
  checkChargeStatusForPaymentIntent (endPoint, key, objectId, checkCount) {
    return new Promise((resolve, reject) => {
      if (checkCount && checkCount > 10) {
        reject(new Error('check failed'))
      } else {
        utils.axios.get(`${endPoint}/charges/${objectId}/status`, {
          auth: {
            username: key,
            password: ''
          }
        }).then((result) => {
          const data = result.data
          if (data.status === 'pending') {
            setTimeout(() => {
              this.checkChargeStatusForPaymentIntent(endPoint, key, objectId, (checkCount || 0) + 1).then(resolve, reject)
            }, 1000)
          } else {
            resolve()
          }
        }).catch(reject)
      }
    })
  },
  handleSetupIntentConfirmed (helper, endPoint, key, object, payload, ev) {
    return helper.stripe.confirmCardSetup(payload.clientSecret, {
      payment_method: ev.paymentMethod.id
    }, {
      handleActions: false
    }).then((confirmResult) => {
      if (confirmResult.error) {
        return Promise.reject(new Error(confirmResult.error.message))
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success')
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (confirmResult.setupIntent.status === 'requires_action' ||
            confirmResult.setupIntent.status === 'requires_source_action') {
          return helper.stripe.confirmCardSetup(payload.clientSecret).then((result) => {
            if (result.error) {
              return Promise.reject(new Error(result.error.message))
            } else {
              return this.checkSourceStatusForSetupIntent(endPoint, key, object.id)
            }
          })
        } else {
          return this.checkSourceStatusForSetupIntent(endPoint, key, object.id)
        }
      }
    })
  },
  checkSourceStatusForSetupIntent (endPoint, key, objectId, checkCount) {
    return new Promise((resolve, reject) => {
      if (checkCount && checkCount > 10) {
        reject(new Error('check failed'))
      } else {
        utils.axios.get(`${endPoint}/sources/${objectId}/status`, {
          auth: {
            username: key,
            password: ''
          }
        }).then((result) => {
          const data = result.data
          if (data.status === 'pending') {
            setTimeout(() => {
              this.checkSourceStatusForSetupIntent(endPoint, key, objectId, (checkCount || 0) + 1).then(resolve, reject)
            }, 1000)
          } else {
            resolve()
          }
        }).catch(reject)
      }
    })
  },
  handleChargeToken (endPoint, key, object, ev) {
    return utils.axios.post(`${endPoint}/charges/${object.id}/capture`, {
      extra: {
        token: ev.token.id
      }
    }, {
      auth: {
        username: key,
        password: ''
      }
    })
  },
  handleSourceToken (endPoint, key, object, ev) {
    return utils.axios.post(`${endPoint}/sources/${object.id}/confirm`, {
      extra: {
        token: ev.token.id
      }
    }, {
      auth: {
        username: key,
        password: ''
      }
    })
  },
  processApplePayOrGooglePay (type, object, credential, key, options, helper, payload, redirectUrl, resolve, reject) {
    const modalElem = utils.createModal()
    const iframeElem = utils.createIFrame()
    const params = utils.getRedirectDefaultParams(options)
    iframeElem.src = utils.buildUrl(redirectUrl, params)

    utils.appendIFrame(iframeElem, modalElem)
    document.body.appendChild(modalElem)

    const endPoint = options.endPoint || process.env.API_ENDPOINT
    const chargeOrigin = utils.parseOrigin(redirectUrl)
    // applepay label
    const label = (object.extra && object.extra.shopName) || object.description || payload.appName || 'Summary'
    const paymentRequest = helper.stripe.paymentRequest({
      country: 'JP',
      currency: type === 'charge' ? object.currency.toLowerCase() : 'jpy',
      total: {
        label,
        amount: type === 'charge' ? object.amount : 0,
        pending: type !== 'charge'
      },
      jcbEnabled: !helper.config.jcbDisabled
    })

    const self = this
    const register = function (modalElem) {
      const unregister = utils.listenMessage(window, chargeOrigin, (cmd, data) => {
        // add message event handler
        if (cmd === 'mounted') {
          iframeElem.setAttribute('data-ready', 'true')

          paymentRequest.canMakePayment().then((result) => {
            if (result) {
              utils.postMessage(iframeElem.contentWindow, chargeOrigin, 'ready')
            } else {
              utils.postMessage(iframeElem.contentWindow, chargeOrigin, 'unsupported')
            }
          })

          const done = function (ev) {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              resolve({
                type: 'success'
              })
            })
          }
          const failure = function (ev, err) {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              ev.complete('fail')
              reject(err)
            })
          }
          if (payload.clientSecret) {
            paymentRequest.on('paymentmethod', (ev) => {
              if (type === 'source') {
                self.handleSetupIntentConfirmed(helper, endPoint, key, object, payload, ev).then(() => {
                  done(ev)
                }).catch((err) => {
                  failure(ev, err)
                })
              } else {
                self.handlePaymentIntentConfirmed(helper, endPoint, key, object, payload, ev).then(() => {
                  done(ev)
                }).catch((err) => {
                  failure(ev, err)
                })
              }
            })
          } else {
            paymentRequest.on('token', (ev) => {
              if (type === 'source') {
                self.handleSourceToken(endPoint, key, object, ev).then(() => {
                  ev.complete('success')
                  done(ev)
                }).catch((err) => {
                  failure(ev, err)
                })
              } else {
                self.handleChargeToken(endPoint, key, object, ev).then(() => {
                  ev.complete('success')
                  done(ev)
                }).catch((err) => {
                  failure(ev, err)
                })
              }
            })
          }
          paymentRequest.on('cancel', () => {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              resolve({
                type: 'cancel'
              })
            })
          })
        } else if (cmd === 'close') {
          unregister()
          utils.destroyModal(modalElem).then(() => {
            resolve({
              type: 'cancel'
            })
          })
        } else if (cmd === 'confirm') {
          paymentRequest.show()
        }
      })
    }
    register(modalElem)
  },
  handle (type, object, credential, key, options, helper, handleOptions) {
    if (object.paymentMethod === 'creditcard') {
      if (object.resource !== 'web') {
        return Promise.reject(
          new ElepayError('1_000_000_10105', `${type} object is not valid, resource is not supported`)
        )
      }
      return this.handleCreditcard(type, object, credential, key, options, helper, handleOptions)
    } else if (object.paymentMethod === 'applepay') {
      if (object.resource !== 'web') {
        return Promise.reject(
          new ElepayError('1_000_000_10105', `${type} object is not valid, resource is not supported`)
        )
      }
      return this.handleApplePayCharge(type, object, credential, key, options, helper)
    } else if (object.paymentMethod === 'googlepay') {
      if (object.resource !== 'web') {
        return Promise.reject(
          new ElepayError('1_000_000_10105', `${type} object is not valid, resource is not supported`)
        )
      }
      return this.handleGooglePay(type, object, credential, key, options, helper)
    } else {
      return Promise.reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
    }
  },
  handleCharge (charge, credential, key, options, helper, handleOptions) {
    return this.handle('charge', charge, credential, key, options, helper, handleOptions)
  },
  handleSource (source, credential, key, options, helper, handleOptions) {
    return this.handle('source', source, credential, key, options, helper, handleOptions)
  },
  init (config, paymentMethod, options) {
    return utils.loadJs('stripe', 'https://js.stripe.com/v3/').then(() => {
      return new StripeHelper(config, options)
    }).catch((err) => {
      return Promise.reject(new ElepayError('1_000_000_50001', `failed to load provider sdk. ${err}`))
    })
  },
  needInitBeforeHandle (charge) {
    return charge.paymentMethod === 'applepay' || charge.paymentMethod === 'googlepay'
  }
}

function StripeHelper (config, options) {
  let locale = options.locale || 'auto'
  const hyphenIndex = locale.indexOf('-')
  if (hyphenIndex >= 0) {
    locale = locale.substring(0, hyphenIndex)
  }
  this.stripe = window.Stripe(config.sdkKey, {
    locale
  })
  this.config = config
}

StripeHelper.prototype.createElements = function (elementsOption, clientSecret) {
  if (!this.stripe) {
    throw new ElepayError('1_000_000_50000', 'unknown error')
  }
  const options = {}
  if (clientSecret) {
    options.clientSecret = clientSecret
  }
  const elements = this.stripe.elements(options)
  const list = []
  elementsOption.forEach((option) => {
    const element = elements.create(option.type, {
      style: option.style,
      classes: option.classes
    })
    if (option.selector) {
      element.mount(option.selector)
    }
    list.push(element)
  })
  return list
}

StripeHelper.prototype.createStripeToken = function (elementList, tokenData) {
  if (!this.stripe) {
    return Promise.reject(new ElepayError('1_000_000_50000', 'unknown error'))
  }
  return this.stripe.createToken(utils.isArray(elementList) ? elementList[0] : elementList, tokenData)
}

StripeHelper.prototype.createStripeSource = function (elementList, sourceData) {
  if (!this.stripe) {
    return Promise.reject(new ElepayError('1_000_000_50000', 'unknown error'))
  }
  return this.stripe.createSource(utils.isArray(elementList) ? elementList[0] : elementList, sourceData)
}

StripeHelper.prototype.confirmStripePaymentIntent = function (elementList, billingData, clientSecret) {
  if (!this.stripe) {
    return Promise.reject(new ElepayError('1_000_000_50000', 'unknown error'))
  }
  if (!clientSecret) {
    return Promise.reject(new ElepayError('1_000_000_50000', 'unknown error'))
  }
  // waiting 1 second for webhook processing
  return new Promise((resolve, reject) => {
    this.stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: utils.isArray(elementList) ? elementList[0] : elementList,
        billing_details: billingData
      }
    }).then((result) => {
      setTimeout(() => {
        resolve(result)
      }, 1000)
    }).catch(reject)
  })
}

StripeHelper.prototype.confirmStripeSetupIntent = function (elementList, billingData, clientSecret) {
  if (!this.stripe) {
    return Promise.reject(new ElepayError('1_000_000_50000', 'unknown error'))
  }
  if (!clientSecret) {
    return Promise.reject(new ElepayError('1_000_000_50000', 'unknown error'))
  }
  // waiting 2 second for webhook processing
  return new Promise((resolve, reject) => {
    this.stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: utils.isArray(elementList) ? elementList[0] : elementList,
        billing_details: billingData
      }
    }).then((result) => {
      setTimeout(() => {
        resolve(result)
      }, 2000)
    }).catch(reject)
  })
}
