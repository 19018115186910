import utils from '../utils'
import ElepayError from '../error'

function handle (type, object, credential, key, options) {
  return new Promise((resolve, reject) => {
    if (object.paymentMethod !== 'wechatpay') {
      return reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
    }
    if (object.resource === 'web') {
      if (!credential.redirectUrl) {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }
      const params = utils.getRedirectDefaultParams(options)
      const redirectUrl = utils.replaceKey(credential.redirectUrl, key)
      utils.redirect(utils.buildUrl(redirectUrl, params))
      return setTimeout(() => resolve({}), 3000)
    } else {
      return reject(new ElepayError('1_000_000_10105', 'charge object is not valid, resource is not supported'))
    }
  })
}

export default {
  handleCharge (charge, credential, key, options, helper) {
    return handle.call(this, 'charge', charge, credential, key, options, helper)
  },
  handleSource (source, credential, key, options, helper) {
    return handle.call(this, 'source', source, credential, key, options, helper)
  }
}
