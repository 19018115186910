import 'core-js/modules/es.promise.finally'
import utils from './utils'
import providers from './providers'
import ElepayError from './error'
import CodesWidget from './CodesWidget'

/**
 * SDKを初期化
 * @class
 *
 * @param {string} key 必須 公開鍵
 * @param {object} options オプション
 * @param {string} options.locale 言語設定。デフォルトはauto(ブラウザのロケールに応じて自動設定)で、他にja,en,zh-CN,zh-TWを指定できます。
 * @returns {Elepay} Elepay インスタンス
 */
function Elepay (key, options) {
  this.utils = utils
  this.key = key
  this.options = options || {}
}

Elepay.prototype.initProvider = function (provider, paymentMethod, info) {
  const endPoint = this.options.endPoint || process.env.API_ENDPOINT
  return utils.axios.get(`${endPoint}/config/providers`, {
    auth: {
      username: this.key,
      password: ''
    }
  }).then((response) => {
    const payload = response.data.payload
    const configInfo = this.aes256decode(payload)
    const providerHandler = providers[provider] || providers.default

    return providerHandler.init(configInfo[provider] || {}, paymentMethod, this.options, info).then((helper) => {
      if (helper) {
        this[provider] = helper
      }
    })
  })
}

Elepay.prototype.aes256decode = function (credential) {
  return JSON.parse(utils.aes256decode(credential.substr(6), `VfqCFNzeZQ${credential.substr(0, 6)}`))
}

Elepay.prototype.aes256encode = function (object) {
  const ts = new Date().getTime() + ''
  const nonce = ts.substring(ts.length - 6)
  return nonce + utils.aes256encode(JSON.stringify(object), `VfqCFNzeZQ${nonce}`)
}

/**
 * Charge オブジェクトを渡して、支払い処理を行います。
 * @function
 *
 * @param {object} charge 必須 サーバー側作成した Charge オブジェクト
 * @returns {Promise} 支払い処理結果のPromise。frontUrl設定必要の決済方法はfrontUrlで決済結果を戻ります。
 *
 * @example
 * elepay.handleCharge(chargeObject).then(function(result) {
 *   // 正常処理
 *   if (result.type === 'cancel') {
 *     // 支払いキャンセル
 *   } else if (result.type === 'success') {
 *     // 支払い成功
 * }).catch(function(err) {
 *   // エラー処理
 * })
 */
Elepay.prototype.handleCharge = function (charge, handleOptions) {
  return new Promise((resolve, reject) => {
    if (charge && typeof charge === 'string') {
      try {
        charge = JSON.parse(charge)
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }
    }
    if (charge && charge.id) {
      if (!charge.credential) {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }
      // verify charge
      if (charge.resource !== 'mini') {
        const endPoint = this.options.endPoint || process.env.API_ENDPOINT
        utils.axios.post(`${endPoint}/charges/${charge.id}/verify`, {
          metadata: {
            credential: this.aes256encode({
              type: 'web',
              device: {
                ua: window.navigator.userAgent
              },
              meta: {}
            })
          }
        }, {
          auth: {
            username: this.key,
            password: ''
          }
        }).then(() => { }, () => { })
      }

      // for charge using source
      if (charge.status === 'captured') {
        return resolve({
          type: 'success'
        })
      }

      let credential
      try {
        credential = this.aes256decode(charge.credential)
        if (!credential) {
          return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
        }
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }
      const provider = credential.provider
      const providerHandler = providers[provider] || providers.default
      if (!providerHandler) {
        reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
      } else {
        if (!charge.liveMode && credential.redirectUrl) {
          providers.test.handleCharge(charge, credential, this.key, this.options, null, handleOptions).then(resolve, reject)
        } else {
          let initPromise = Promise.resolve(true)
          if (providerHandler.needInitBeforeHandle && providerHandler.needInitBeforeHandle(charge)) {
            initPromise = this.initProvider(provider, charge.paymentMethod)
          }
          initPromise.then(() => {
            return providerHandler.handleCharge(charge, credential, this.key, this.options, this[provider], handleOptions)
          }).then(resolve, reject)
        }
      }
    } else {
      reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
    }
  })
}

/**
 * Source オブジェクトを渡して、承認処理を行います。
 * @function
 *
 * @param {object} source 必須 サーバー側作成した Source オブジェクト
 * @returns {Promise} 承認処理結果のPromise。frontUrl設定必要の決済方法はfrontUrlで承認結果を戻ります。
 *
 * @example
 * elepay.handleSource(source).then(function(result) {
 *   // 正常処理
 *   if (result.type === 'cancel') {
 *     // キャンセル
 *   } else if (result.type === 'success') {
 *     // 承認成功
 * }).catch(function(err) {
 *   // エラー処理
 * })
 */
Elepay.prototype.handleSource = function (source, handleOptions) {
  return new Promise((resolve, reject) => {
    if (source && typeof source === 'string') {
      try {
        source = JSON.parse(source)
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', 'source object is not valid'))
      }
    }
    if (source && source.id) {
      if (!source.credential) {
        return reject(new ElepayError('1_000_000_10105', 'source object is not valid'))
      }
      const endPoint = this.options.endPoint || process.env.API_ENDPOINT
      utils.axios.post(`${endPoint}/sources/${source.id}/verify`, {
        metadata: {
          credential: this.aes256encode({
            type: 'web',
            device: {
              ua: window.navigator.userAgent
            },
            meta: {}
          })
        }
      }, {
        auth: {
          username: this.key,
          password: ''
        }
      }).then(() => { }, () => { })

      let credential
      try {
        credential = this.aes256decode(source.credential)
        if (!credential) {
          return reject(new ElepayError('1_000_000_10105', 'source object is not valid'))
        }
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', 'source object is not valid'))
      }
      const provider = credential.provider
      const providerHandler = providers[provider] || providers.default
      if (!providerHandler) {
        reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
      } else {
        if (!source.liveMode && credential.redirectUrl) {
          providers.test.handleSource(source, credential, this.key, this.options, null, handleOptions).then(resolve, reject)
        } else {
          let initPromise = Promise.resolve(true)
          if (providerHandler.needInitBeforeHandle && providerHandler.needInitBeforeHandle(source)) {
            initPromise = this.initProvider(provider, source.paymentMethod)
          }
          initPromise.then(() => {
            return providerHandler.handleSource(source, credential, this.key, this.options, this[provider], handleOptions)
          }).then(resolve, reject)
        }
      }
    } else {
      reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
    }
  })
}

Elepay.prototype.prepareClientPayment = async function (params) {
  if (params.paymentMethod !== 'applepay') {
    return null
  }
  const endPoint = this.options.endPoint || process.env.API_ENDPOINT
  const response = await utils.axios.post(`${endPoint}/payment-info`, params, {
    headers: {
      authorization: `Bearer ${this.key}`
    }
  })
  const paymentInfo = response.data
  if (paymentInfo.providerKey !== 'gmo') {
    return null
  }
  return paymentInfo
}

Elepay.prototype.handleClientPayment = function (paymentInfo, createCharge) {
  const endPoint = this.options.endPoint || process.env.API_ENDPOINT
  const clientPaymentRequest = paymentInfo.clientPaymentRequest
  const ApplePaySession = window.ApplePaySession
  const session = new ApplePaySession(3, clientPaymentRequest)
  return new Promise((resolve, reject) => {
    let chargeId
    session.onvalidatemerchant = async (event) => {
      try {
        chargeId = await createCharge()
        const response = await this.utils.axios.post(`${endPoint}/webhooks/applepay/validate`, {
          chargeId,
          url: event.validationURL,
          domain: window.location.hostname,
          displayLabel: clientPaymentRequest.total.label
        })
        session.completeMerchantValidation(response.data)
      } catch (err) {
        console.error(err)
        session.completeMerchantValidation({})
        reject(err)
      }
    }
    session.onpaymentauthorized = async (event) => {
      try {
        await this.utils.axios.post(`${endPoint}/charges/${chargeId}/capture`, {
          extra: {
            token: JSON.stringify(event.payment.token)
          }
        }, {
          headers: {
            authorization: `Bearer ${this.key}`
          }
        })
        session.completePayment(ApplePaySession.STATUS_SUCCESS)
        resolve()
      } catch (err) {
        session.completePayment(ApplePaySession.STATUS_FAILURE)
        reject(err)
      }
    }
    session.oncancel = () => {
      reject(new DOMException('The operation was aborted.', 'AbortError'))
    }
    session.begin()
  })
}

Elepay.prototype.handleOpenUrl = function () {
  let query = utils.parseQuery()
  // check if in liff
  if (query['liff.state']) {
    query = utils.parseQuery(query['liff.state'])
  }
  if (query['elepay.state']) {
    // need handle this url
    const state = JSON.parse(query['elepay.state'])
    return new Promise((resolve, reject) => {
      const provider = state.provider
      const providerHandler = providers[provider]
      if (!providerHandler) {
        resolve({
          handled: false
        })
      } else {
        if (providerHandler.handleOpenUrl) {
          return providerHandler.handleOpenUrl(state, this.options).then(resolve, reject)
        } else {
          resolve({
            handled: false
          })
        }
      }
    })
  } else {
    return Promise.resolve({
      handled: false
    })
  }
}

/**
 * EasyQRウィジェットを生成します
 * @function
 *
 * @param {object} options ウィジェットオプション
 * @param {string} options.container 配置先のDOM要素のCSSセレクターを表す文字列
 * @param {string} options.direction ウィジェットレイアウト 縦:vertical(デフォルト) 横:horizontal
 * @param {boolean} options.icon trueならブランドアイコン入りQRコードを表示。デフォルトはfalse
 * @param {boolean} options.parts.amount trueなら金額を表示。デフォルトはtrue
 * @param {boolean} options.parts.paymentLogo trueなら決済方法アイコンを表示。デフォルトはtrue
 * @param {boolean} options.parts.tip trueならヘルプメッセージを表示。デフォルトはtrue
 * @param {boolean} options.theme.primaryColor メイン色
 * @param {boolean} options.theme.borderColor ウィジェット罫線色。null:罫線なし
 * @param {boolean} options.theme.backgroundColor ウィジェット背景色。デフォルトは白
 * @returns {CodesWidget} EasyQRウィジェットインスタンス
 *
 * @example
 * var widget = elepay.createCodeWidget({
 *   container: '#widget'
 * })
 * widget.on('success', () => {
 *   // 決済完了後処理
 * })
 * widget.on('expired', () => {
 *   // 新しいEasyQRコードを生成するなと
 * })
 * widget.show('cod_028123beb9f8c853fa845f4')
 */
Elepay.prototype.createCodeWidget = function (options) {
  return new CodesWidget(options, this)
}

/**
 * EasyCheckout 処理を行います。
 * @function
 *
 * @param {object} code 必須 サーバー側作成した EasyQR オブジェクトID
 * @returns {Promise} Checkout処理のPromise。エラーの場合だけ、処理する必要があります。
 *
 * @example
 * elepay.checkout('cod_028123beb9f8c853fa845f4').catch(function(err) {
 *   // エラー処理
 * })
 */
Elepay.prototype.checkout = function (code, options = {}) {
  if (!code || typeof code !== 'string') {
    throw new ElepayError('1_000_000_10105', 'Code is required')
  }

  const endPoint = this.options.endPoint || process.env.API_ENDPOINT
  return utils.axios.get(`${endPoint}/codes/${code}`, {
    auth: {
      username: this.key,
      password: ''
    }
  }).then(res => {
    const { mode } = options
    const { locale } = this.options
    const params = { mode: mode || 'auto' }
    if (locale) params.locale = locale
    // replace codeUrl /pay to /checkout
    const codeUrl = res.data.codeUrl.replace(/\/pay(\?|$)/, '/checkout$1')
    window.location.href = utils.buildUrl(codeUrl, params)
  })
}

Elepay.prototype.destroy = function () {
  this.utils.destroyModal()
}

Elepay.utils = utils

export default Elepay
