import BaseEventObject from 'base-event-object'
import mergeWith from 'lodash/mergeWith'
import utils from './utils'
import ElepayError from './error'

const defaultOptions = {
  container: '', // 指定掛載DOM, 默認直接掛載在Body上
  direction: 'vertical', // 指定佈局方向，取值範圍 [ 'horizontal', 'vertical' ]，默認為 'vertical'
  icon: false, // 是否在qr中显示icon
  parts: {}, // 設定顯示的部件
  theme: {}, // 主題相關顏色 map
  styles: {} // 自定義樣式表
}

/**
 * EasyQRウィジェットクラス
 * @class
 */
class CodesWidget {
  constructor (options = {}, elepayInstance) {
    this.options = mergeWith(
      {},
      defaultOptions,
      options,
      (objValue, srcValue) => {
        if (srcValue === null || srcValue === undefined) {
          return objValue
        }
      }
    )

    this.__isReady = false
    this.__widgetElem = null
    this.__iframeElem = null
    this.__code = null
    this.elepayInstance = elepayInstance

    /**
     * 支払完了イベント
     * @event CodesWidget#success
     * @param ev {object} イベント
     * @param ev.type {string} success
     * @param codeObject {object} EasyQRコードオブジェクト
     * @example
     * widget.on('success', function (ev, codeObject) {
     *   // 決済完了後処理
     * })
     */
    /**
     * EasyQRコード期限切れイベント
     * @event CodesWidget#expired
     * @param ev {object} イベント
     * @param ev.type {string} expired
     * @param codeObject {object} EasyQRコードオブジェクト
     * @example
     * widget.on('expired', function (ev, codeObject) {
     *   // 新しいEasyQRコードを生成するなと
     * })
     */
    /**
     * エラーイベント
     * @event CodesWidget#error
     * @param ev {object} イベント
     * @param ev.type {string} error
     * @param error {Error} エラーオブジェクト
     * @example
     * widget.on('error', function (ev, err) {
     *   // エラー処理
     * })
     */
    Object.assign(this, new BaseEventObject({
      events: [
        'success',
        'expired',
        'error'
      ],
      onceEvents: [
        'ready'
      ]
    }))

    this.__init()
  }

  __init () {
    const chargeOrigin = this.__chargeOrigin = this.elepayInstance.options.chargeOrigin || process.env.IFRAME_ORIGIN
    const iframeURL = `${chargeOrigin}/codes-widget.html?key=<key>`

    const iframeElem = this.__iframeElem = utils.createIFrame()
    const params = Object.assign({}, {
      options: JSON.stringify(this.options),
      ...utils.getRedirectDefaultParams(this.elepayInstance.options)
    })
    const redirectUrl = utils.buildUrl(utils.replaceKey(iframeURL, this.elepayInstance.key), params)

    iframeElem.src = utils.buildUrl(redirectUrl, params)

    let container
    if (this.options.container) {
      container = document.querySelector(this.options.container)
    }

    if (container) {
      utils.appendIFrame(iframeElem, container, true)
      this.__widgetElem = iframeElem
    } else {
      const modalElem = utils.createModal()
      utils.appendIFrame(iframeElem, modalElem, true)
      document.body.appendChild(modalElem)
      this.__widgetElem = modalElem
    }

    this.__unregister = utils.listenMessage(window, chargeOrigin, (cmd, data) => {
      if (cmd === 'mounted') {
        this.__isReady = true
        this.__sentCode()
      } else {
        this.emit(cmd, data)
      }
    })

    return this
  }

  __sentCode () {
    if (!this.__isReady || !this.__code) return
    utils.postMessage(this.__iframeElem.contentWindow, this.__chargeOrigin, 'code', this.__code)
    return this
  }

  /**
   * EasyQRウィジェットを表示します
   * @param {string} code 必須 サーバー側作成した EasyQR オブジェクトID
   *
   * @example
   * widget.show('cod_028123beb9f8c853fa845f4')
   */
  show (code) {
    if (code) {
      this.__code = code
      this.__sentCode()
    } else {
      throw new ElepayError('1_000_000_10105', 'Code is required')
    }
    return this
  }

  /**
   * EasyQRウィジェットを破棄します
   */
  destroy () {
    this.__unregister && this.__unregister()
    this.__unregister = null
    this.__widgetElem && this.__widgetElem.remove()
    this.__widgetElem = null
    this.__iframeElem = null
    typeof this.off === 'function' && this.off()
  }
}

export default CodesWidget
