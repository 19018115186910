import utils from '../utils'
import ElepayError from '../error'

function handle (type, object, credential, key, options, helper) {
  return new Promise((resolve, reject) => {
    if (object.paymentMethod !== 'paidy') {
      return reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
    }
    if (object.resource !== 'web') {
      reject(new ElepayError('1_000_000_10105', `${type} object is not valid, resource is not supported`))
    }
    if (!credential.redirectUrl) {
      return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
    }

    const modalElem = utils.createModal()
    const iframeElem = utils.createIFrame()
    const params = utils.getRedirectDefaultParams(options)
    const redirectUrl = utils.replaceKey(credential.redirectUrl, key)
    iframeElem.src = utils.buildUrl(redirectUrl, params)

    utils.appendIFrame(iframeElem, modalElem)
    document.body.appendChild(modalElem)

    const origin = utils.parseOrigin(redirectUrl)

    // Paidy 是在 SDK WEB 中打開一個 iframe，並且會與 top window 進行通信
    // 但 SDK WEB 畫面也是在 iframe 中打開，導致該畫面的 window 已經不是 top window
    // 因此會造成 SDK WEB 畫面接收接受不到 message
    // 這段代碼的目的是將 message 轉發到 SDK WEB
    window.addEventListener('message', event => {
      if (event.data && event.data.event) {
        iframeElem.contentWindow &&
        iframeElem.contentWindow.postMessage(event.data, origin)
      }
    })

    const register = function (modalElem) {
      const unregister = utils.listenMessage(window, origin, (cmd, data) => {
        // add message event handler
        if (cmd === 'close') {
          unregister()
          utils.destroyModal(modalElem).then(() => {
            resolve({
              type: 'cancel'
            })
          })
        } else if (cmd === 'success') {
          unregister()
          utils.destroyModal(modalElem).then(() => {
            resolve({
              type: 'success'
            })
          })
        } else if (cmd === 'failure') {
          unregister()
          utils.destroyModal(modalElem).then(() => {
            reject(data)
          })
        }
      })
    }
    register(modalElem)
  })
}

function PaidyHelper (config) {
  this.config = config
}

PaidyHelper.prototype.launch = function (type, payload = {}) {
  const params = {
    api_key: this.config.sdkKey,
    closed: (callbackData) => {
      if (this.callbackHandler) {
        this.callbackHandler(callbackData)
        this.callbackHandler = null
      }
    }
  }

  if (type === 'source') {
    params.token = {
      wallet_id: this.config.wallet_id || 'default',
      type: 'recurring'
    }
  }

  const paidyHandler = window.Paidy.configure(params)
  const {
    id,
    amount,
    currency,
    checkout,
    extra = {}
  } = payload

  if (checkout) {
    paidyHandler.launch(checkout)
  } else {
    let buyer = { name1: null }
    let shippingAddress = null
    if (!extra.digital && !this.config.digital) {
      buyer = {
        email: extra.buyerEmail,
        name1: extra.buyerName,
        name2: extra.buyerNameKana || '',
        phone: extra.buyerPhone,
        dob: extra.buyerBirth || ''
      }
      shippingAddress = {
        line1: extra.buyerAddress1 || '',
        line2: extra.buyerAddress2 || '',
        city: extra.buyerCity || '',
        state: extra.buyerState || '',
        zip: extra.buyerZip || ''
      }
    }
    const data = {
      store_name: extra.shopName || '',
      buyer: buyer,
      buyer_data: {
        user_id: extra.buyerDataUserId || '',
        age: extra.buyerDataAge || 0,
        order_count: extra.buyerDataOrderCount || 0,
        ltv: extra.buyerDataLifeTimeValue || 0,
        last_order_amount: extra.lastOrderAmount || 0,
        last_order_at: extra.lastOrderAt || 0
      },
      shipping_address: shippingAddress,
      description: extra.description || ''
    }
    if (type === 'charge') {
      data.amount = amount
      data.currency = currency
      data.order = {
        items: [
          {
            id: extra.productId || '',
            quantity: 1,
            title: extra.productName || '',
            unit_price: amount,
            description: extra.productDescription || ''
          }
        ],
        order_ref: id,
        shipping: 0,
        tax: 0
      }
    }
    paidyHandler.launch(data)
  }
  return new Promise((resolve, reject) => {
    this.callbackHandler = (callbackData) => {
      const status = callbackData.status.toLowerCase()
      if (status === 'closed') {
        resolve({
          status: 'cancel'
        })
      } else if (status === 'authorized' || status === 'active') {
        resolve({
          status: 'finish',
          token: callbackData.id
        })
      } else if (status === 'rejected') {
        resolve({
          status: 'error'
        })
      } else {
        reject(new ElepayError('1_000_000_50000', 'unknown error'))
      }
    }
  })
}

export default {
  handleCharge (charge, credential, key, options, helper) {
    return handle('charge', charge, credential, key, options, helper)
  },
  handleSource (source, credential, key, options, helper) {
    return handle('source', source, credential, key, options, helper)
  },
  init (config) {
    return utils.loadJs('paidy', 'https://apps.paidy.com/').then(() => {
      return new PaidyHelper(config)
    }).catch((err) => {
      return Promise.reject(new ElepayError('1_000_000_50001', `failed to load provider sdk. ${err}`))
    })
  },
  needInitBeforeHandle () {
    return true
  }
}
