import utils from '../utils'
import ElepayError from '../error'

export default {
  handleCreditcard (type, object, credential, key, options, helper, handleOptions) {
    return new Promise((resolve, reject) => {
      if (!credential.payload) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
      let payload
      try {
        payload = JSON.parse(credential.payload)
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid. ${e}`))
      }
      if (!payload.creditcard) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
      const containerElem = handleOptions && handleOptions.containerDiv ? document.getElementById(handleOptions.containerDiv) : null
      const modalElem = containerElem || utils.createModal()
      const iframeElem = utils.createIFrame()
      const params = utils.getRedirectDefaultParams(options)
      if (containerElem) {
        params.container = 'true'
      }
      const redirectUrl = utils.buildUrl(utils.replaceKey(payload.creditcard, key), params)
      iframeElem.src = redirectUrl

      utils.appendIFrame(iframeElem, modalElem)
      if (!containerElem) {
        document.body.appendChild(modalElem)
      }

      const origin = utils.parseOrigin(redirectUrl)
      const register = function (modalElem) {
        const unregister = utils.listenMessage(window, origin, (cmd, data) => {
          // add message event handler
          if (cmd === 'mounted') {
            iframeElem.setAttribute('data-ready', 'true')
            utils.postMessage(iframeElem.contentWindow, origin, 'ready')
          } else if (cmd === 'close') {
            unregister()
            utils.destroyModal(modalElem, !!containerElem).then(() => {
              resolve({
                type: 'cancel'
              })
            })
          } else if (cmd === 'token') {
            // TODO when to use
            unregister()
            resolve({
              type: 'token',
              token: data
            })
          } else if (cmd === 'success') {
            unregister()
            utils.destroyModal(modalElem, !!containerElem).then(() => {
              resolve({
                type: 'success'
              })
            })
          } else if (cmd === 'failure') {
            unregister()
            utils.destroyModal(modalElem, !!containerElem).then(() => {
              reject(data)
            })
          }
        })
      }

      register(modalElem)
    })
  },
  handleApplePayCharge (charge, credential, key, options, helper) {
    return new Promise((resolve, reject) => {
      if (!credential.payload) {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }
      let payload
      try {
        payload = JSON.parse(credential.payload)
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', `charge object is not valid. ${e}`))
      }
      if (!payload.applepay) {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }

      const modalElem = utils.createModal()
      const iframeElem = utils.createIFrame()
      const params = utils.getRedirectDefaultParams(options)
      const redirectUrl = utils.replaceKey(payload.applepay, key)
      iframeElem.src = utils.buildUrl(redirectUrl, params)

      utils.appendIFrame(iframeElem, modalElem)
      document.body.appendChild(modalElem)

      const endPoint = options.endPoint || process.env.API_ENDPOINT
      const chargeOrigin = utils.parseOrigin(redirectUrl)
      // applepay label
      const label = (charge.extra && charge.extra.shopName) || charge.description || 'Summary'
      const applePaySessionConfig = helper.createApplePaySessionConfig('final', label, charge.amount)

      const register = function (modalElem) {
        const unregister = utils.listenMessage(window, chargeOrigin, (cmd, data) => {
          // add message event handler
          if (cmd === 'mounted') {
            iframeElem.setAttribute('data-ready', 'true')

            utils.canMakeApplePayPayments().then((result) => {
              if (result) {
                utils.postMessage(iframeElem.contentWindow, chargeOrigin, 'ready')
              } else {
                utils.postMessage(iframeElem.contentWindow, chargeOrigin, 'unsupported')
              }
            })
          } else if (cmd === 'close') {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              resolve({
                type: 'cancel'
              })
            })
          } else if (cmd === 'confirm') {
            const session = new window.ApplePaySession(3, applePaySessionConfig)
            session.onvalidatemerchant = (event) => {
              return utils.axios.post(`${endPoint}/webhooks/applepay/validate`, {
                chargeId: charge.id,
                url: event.validationURL,
                domain: window.location.hostname,
                displayLabel: label
              }).then((response) => {
                console.log(response.data)
                session.completeMerchantValidation(response.data)
              }).catch((err) => {
                console.error(err)
                session.completeMerchantValidation({})
              })
            }
            session.onpaymentauthorized = (event) => {
              return utils.axios.post(`${endPoint}/charges/${charge.id}/capture`, {
                extra: {
                  token: JSON.stringify(event.payment.token.paymentData)
                }
              }, {
                auth: {
                  username: key,
                  password: ''
                }
              }).then(() => {
                unregister()
                utils.destroyModal(modalElem).then(() => {
                  session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
                  resolve({
                    type: 'success'
                  })
                })
              }).catch((err) => {
                unregister()
                utils.destroyModal(modalElem).then(() => {
                  session.completePayment(window.ApplePaySession.STATUS_FAILURE)
                  reject(err)
                })
              })
            }
            session.oncancel = () => {
              unregister()
              utils.destroyModal(modalElem).then(() => {
                resolve({
                  type: 'cancel'
                })
              })
            }
            session.begin()
          }
        })
      }
      register(modalElem)
    })
  },
  handleGooglePayCharge (charge, credential, key, options, helper) {
    return new Promise((resolve, reject) => {
      const endPoint = options.endPoint || process.env.API_ENDPOINT
      // applepay label
      const label = (charge.extra && charge.extra.shopName) || charge.description || 'Summary'

      const paymentsClient = helper.getGooglePaymentsClient()
      paymentsClient.isReadyToPay(helper.getGoogleIsReadyToPayRequest()).then((response) => {
        if (response.result) {
          const paymentDataRequest = helper.getGooglePaymentDataRequest('FINAL', label, charge.amount)
          return paymentsClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
            // handle the response
            return paymentData.paymentMethodData.tokenizationData.token
          })
        } else {
          return Promise.reject(new ElepayError('1_000_000_50000', 'google pay is not ready'))
        }
      }).then((paymentToken) => {
        return utils.axios.post(`${endPoint}/charges/${charge.id}/capture`, {
          extra: {
            token: paymentToken
          }
        }, {
          auth: {
            username: key,
            password: ''
          }
        })
      }).then(() => {
        resolve({
          type: 'success'
        })
      }).catch((err) => {
        console.error(err)
        reject(err)
      })
    })
  },
  handle (type, object, credential, key, options, helper, handleOptions) {
    if (object.paymentMethod === 'creditcard') {
      if (object.resource !== 'web') {
        return Promise.reject(new ElepayError('1_000_000_10105', 'charge object is not valid, resource is not supported'))
      }
      return this.handleCreditcard(type, object, credential, key, options, helper, handleOptions)
    } else if (type === 'charge' && object.paymentMethod === 'applepay') {
      if (object.resource !== 'web') {
        return Promise.reject(new ElepayError('1_000_000_10105', 'charge object is not valid, resource is not supported'))
      }
      return this.handleApplePayCharge(object, credential, key, options, helper)
    } else if (type === 'charge' && object.paymentMethod === 'googlepay') {
      if (object.resource !== 'web') {
        return Promise.reject(new ElepayError('1_000_000_10105', 'charge object is not valid, resource is not supported'))
      }
      return this.handleGooglePayCharge(object, credential, key, options, helper)
    } else {
      return Promise.reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
    }
  },
  handleCharge (charge, credential, key, options, helper, handleOptions) {
    return this.handle('charge', charge, credential, key, options, helper, handleOptions)
  },
  handleSource (source, credential, key, options, helper, handleOptions) {
    return this.handle('source', source, credential, key, options, helper, handleOptions)
  },
  init (config, paymentMethod) {
    const jsLoadPromises = []
    if (!paymentMethod || paymentMethod === 'creditcard') {
      jsLoadPromises.push(utils.loadJs('sony', config.sdkJs, {
        callBackFunc: 'setToken',
        class: 'spsvToken'
      }))
    }
    if (!paymentMethod || paymentMethod === 'googlepay') {
      jsLoadPromises.push(utils.loadJs('googlepay', 'https://pay.google.com/gp/p/js/pay.js'))
    }
    return Promise.all(jsLoadPromises).then(() => {
      return new SonyHelper(config)
    }).catch((err) => {
      return Promise.reject(new ElepayError('1_000_000_50001', `failed to load provider sdk. ${err}`))
    })
  },
  needInitBeforeHandle (charge) {
    return charge.paymentMethod === 'applepay' || charge.paymentMethod === 'googlepay'
  }
}

function SonyHelper (config) {
  this.config = config
  if (window.SpsvApi) {
    this.SpsvApi = window.SpsvApi
  }
}

SonyHelper.prototype.createSonyToken = function (data) {
  if (!this.SpsvApi) {
    return Promise.reject(new ElepayError('1_000_000_50000', 'unknown error'))
  }
  return new Promise((resolve, reject) => {
    // create callback func
    const timeoutId = setTimeout(() => {
      return reject(new ElepayError('1_000_000_50000', 'unknown error'))
    }, 95000)
    window.setToken = (token, card) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      resolve({
        token,
        card
      })
    }
    this.SpsvApi.spsvCreateToken(
      data.cardNumber,
      data.cardExpiryYear,
      data.cardExpiryMonth,
      data.cardCvc
      // ,
      // '生月日(月)',
      // '生月日(日)',
      // '電話番号',
      // 'カナ氏名(姓)',
      // 'カナ氏名(名)'
    )
  })
}

SonyHelper.prototype.createApplePaySessionConfig = function (type, label, amount) {
  return Object.assign(
    this.config.defaultApplePaySession,
    {
      total: {
        type,
        label,
        amount
      }
    }
  )
}

SonyHelper.prototype.getGooglePaymentsClient = function () {
  if (!this.googlePaymentsClient) {
    this.googlePaymentsClient = new window.google.payments.api.PaymentsClient({
      environment: this.config.sandbox ? 'TEST' : 'PRODUCTION'
    })
  }
  return this.googlePaymentsClient
}

SonyHelper.prototype.getGoogleIsReadyToPayRequest = function () {
  return this.config.defaultGooglePayRequest
}

SonyHelper.prototype.getGooglePaymentDataRequest = function (totalPriceStatus, label, amount) {
  const paymentDataRequest = JSON.parse(JSON.stringify(this.getGoogleIsReadyToPayRequest()))
  paymentDataRequest.allowedPaymentMethods[0].tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'sonypaymentservices',
      gatewayMerchantId: this.config.merchantId
    }
  }
  paymentDataRequest.transactionInfo = {
    countryCode: 'JP',
    currencyCode: 'JPY',
    totalPriceStatus: totalPriceStatus,
    totalPrice: amount + ''
  }
  paymentDataRequest.merchantInfo = {
    merchantName: label
  }
  if (this.config.googlePayMerchantId) {
    paymentDataRequest.merchantInfo.merchantId = this.config.googlePayMerchantId
  }
  return paymentDataRequest
}
