import utils from '../utils'
import ElepayError from '../error'

export default {
  handleCreditcard (type, object, credential, key, options, helper, handleOptions) {
    return new Promise((resolve, reject) => {
      if (credential.redirectUrl) {
        // 3ds
        utils.redirect(credential.redirectUrl)
        return setTimeout(() => resolve({}), 3000)
      }
      if (!credential.payload) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
      let payload
      try {
        payload = JSON.parse(credential.payload)
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid. ${e}`))
      }
      if (!payload.creditcard) {
        return reject(new ElepayError('1_000_000_10105', `${type} object is not valid`))
      }
      const containerElem = handleOptions && handleOptions.containerDiv ? document.getElementById(handleOptions.containerDiv) : null
      const modalElem = containerElem || utils.createModal()
      const iframeElem = utils.createIFrame()
      const params = utils.getRedirectDefaultParams(options)
      if (containerElem) {
        params.container = 'true'
      }
      const redirectUrl = utils.buildUrl(utils.replaceKey(payload.creditcard, key), params)
      iframeElem.src = redirectUrl

      utils.appendIFrame(iframeElem, modalElem)
      if (!containerElem) {
        document.body.appendChild(modalElem)
      }

      const origin = utils.parseOrigin(redirectUrl)
      const register = function (modalElem) {
        const unregister = utils.listenMessage(window, origin, (cmd, data) => {
          // add message event handler
          if (cmd === 'mounted') {
            iframeElem.setAttribute('data-ready', 'true')
            utils.postMessage(iframeElem.contentWindow, origin, 'ready', {
              threeDSecureUrl: payload.threeDSecure || ''
            })
          } else if (cmd === 'close') {
            unregister()
            utils.destroyModal(modalElem, !!containerElem).then(() => {
              resolve({
                type: 'cancel'
              })
            })
          } else if (cmd === 'token') {
            unregister()
            resolve({
              type: 'token',
              token: data
            })
          } else if (cmd === 'success') {
            unregister()
            utils.destroyModal(modalElem, !!containerElem).then(() => {
              resolve({
                type: 'success'
              })
            })
          } else if (cmd === 'failure') {
            unregister()
            utils.destroyModal(modalElem, !!containerElem).then(() => {
              reject(data)
            })
          } else if (cmd === 'threeDSecure') {
            unregister()
            const activeThreeDSecureUrl = utils.buildUrl(payload.threeDSecure, {
              token: data
            })
            utils.redirect(activeThreeDSecureUrl)
          }
        })
      }

      register(modalElem)
    })
  },
  handle (type, object, credential, key, options, helper, handleOptions) {
    if (object.paymentMethod === 'creditcard') {
      if (object.resource !== 'web') {
        return Promise.reject(new ElepayError('1_000_000_10105', 'charge object is not valid, resource is not supported'))
      }
      return this.handleCreditcard(type, object, credential, key, options, helper, handleOptions)
    } else {
      return Promise.reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
    }
  },
  handleCharge (charge, credential, key, options, helper, handleOptions) {
    return this.handle('charge', charge, credential, key, options, helper, handleOptions)
  },
  handleSource (source, credential, key, options, helper, handleOptions) {
    return this.handle('source', source, credential, key, options, helper, handleOptions)
  },
  init (config, paymentMethod) {
    return utils.loadJs('rkbk', config.sdkJs, {
      callBackFunc: 'setToken',
      class: 'rkbkToken'
    }).then(() => {
      return new RakutenbankHelper(config)
    }).catch((err) => {
      return Promise.reject(new ElepayError('1_000_000_50001', `failed to load provider sdk. ${err}`))
    })
  }
}

function RakutenbankHelper (config) {
  this.config = config
  if (window.RkbkApi) {
    this.RkbkApi = window.RkbkApi
  }
}

RakutenbankHelper.prototype.createRakutenbankToken = function (data) {
  if (!this.RkbkApi) {
    return Promise.reject(new ElepayError('1_000_000_50000', 'unknown error'))
  }
  return new Promise((resolve, reject) => {
    // create callback func
    const timeoutId = setTimeout(() => {
      return reject(new ElepayError('1_000_000_50000', 'unknown error'))
    }, 95000)
    window.setToken = (cardNum4, cardExp, token) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      resolve({
        token,
        cardNum4,
        cardExp
      })
    }
    this.RkbkApi.sendInfo(
      this.config.shopId,
      data.certId,
      data.certPwd,
      data.cardNum,
      data.cardExp,
      data.cardHolder,
      data.securityCd
    )
  })
}
