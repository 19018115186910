import utils from '../utils'
import ElepayError from '../error'

export default {
  handleCharge (charge, credential, key, options) {
    return new Promise((resolve, reject) => {
      if (charge.paymentMethod !== 'paypal') {
        return reject(new ElepayError('1_000_000_10104', 'payment method is not supported'))
      }
      if (charge.resource !== 'web') {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid, resource is not supported'))
      }
      if (!credential.redirectUrl) {
        return reject(new ElepayError('1_000_000_10105', 'charge object is not valid'))
      }
      let payload
      try {
        payload = JSON.parse(credential.payload)
      } catch (e) {
        return reject(new ElepayError('1_000_000_10105', `charge object is not valid. ${e}`))
      }

      const modalElem = document.createElement('div')
      modalElem.setAttribute('style', 'display: block; position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; z-index: 19999; overflow-y: scroll;')
      modalElem.setAttribute('id', 'elepay-ui-modal')

      const iframeElem = document.createElement('iframe')
      iframeElem.setAttribute('id', 'elepay-ui-frame')
      iframeElem.setAttribute('style', 'width: 100%; height: 100%; border: none;')

      const params = utils.getRedirectDefaultParams(options)
      const redirectUrl = utils.replaceKey(credential.redirectUrl, key)
      iframeElem.src = utils.buildUrl(redirectUrl, params)

      utils.appendIFrame(iframeElem, modalElem)
      document.body.appendChild(modalElem)

      const origin = utils.parseOrigin(redirectUrl)
      const register = function (modalElem) {
        const unregister = utils.listenMessage(window, origin, (cmd, data) => {
          // add message event handler
          if (cmd === 'mounted') {
            iframeElem.setAttribute('data-ready', 'true')
            let intent = charge.authorize ? 'authorize' : 'capture'
            if (payload.intent) {
              intent = payload.intent
            }
            utils.postMessage(iframeElem.contentWindow, origin, 'ready', {
              intent,
              orderId: payload.orderId
            })
          } else if (cmd === 'close') {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              resolve({
                type: 'cancel'
              })
            })
          } else if (cmd === 'success') {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              resolve({
                type: 'success'
              })
            })
          } else if (cmd === 'failure') {
            unregister()
            utils.destroyModal(modalElem).then(() => {
              reject(data)
            })
          }
        })
      }
      register(modalElem)
    })
  },
  init (config, paymentMethod, options, info) {
    const jsUrl = utils.buildUrl('https://www.paypal.com/sdk/js?components=buttons', {
      intent: info.intent,
      currency: (info.currency || 'jpy').toUpperCase(),
      'client-id': config.clientId,
      'disable-funding': config.disableFunding || 'card'
    })
    const jsAttr = {}
    if (config.bncode) {
      jsAttr['data-partner-attribution-id'] = config.bncode
    }
    return Promise.all([
      utils.loadJs('paypal-sdk', jsUrl, jsAttr)
    ]).then(() => {
      return new PaypalHelper(config)
    }).catch((err) => {
      return Promise.reject(new ElepayError('1_000_000_50001', `failed to load provider sdk. ${err}`))
    })
  }
}

function PaypalHelper (config) {
  this.paypal = window.paypal
  this.config = config
}
